<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 18 : 22"
        v-if="!data.properties.hideLabel"
        :style="computedStyles"
        
      >
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span
          v-if="hasLabel"
          style="color: white; display: inline-block; height: 20px; opacity: 0"
        ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24" class="execute-view"
>
        <el-select
          :style="getStyle"
          :filterable="true"
          :allow-create="true"
          :default-first-option="true"
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          clearable
          :class="{ 'highlighted-border': highlight }"
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value + '_' + option.name"
            :key="option.value + '_' + index"
            :label="option.name"
          ></el-option>
        </el-select>
        <el-select
          :filterable="true"
          :allow-create="true"
          :default-first-option="true"
          v-if="!isView && isList && data.properties.filed_content != 'Hide'"
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          clearable
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value + '_' + option.name"
            :key="option.value + '_' + index"
            :label="option.name"
          ></el-option>
        </el-select>
        <p
          v-if="isView && data.properties.filed_content != 'Hide'"
          :style="getStyle"
        >
          <template v-if="form[data.key]">{{ getListKey }}</template>
          <template v-else>-</template>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponentsExecute-SelectExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "checkhideFields",
    "checkIsDisabled",
    "colorFields",
    "highlight",
  ],
  data() {
    return {
      validations: [],
      isFirstLoad: true, 
      isEditing: false,
      previousValue: null,
      options: [],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      selectedValue: "",
    };
  },
  async mounted() {
    this.loading = true;
    await Promise.all([this.fetchOptions()]);
    this.setValueFromForm();
    this.applyFormRules(this.form[this.data.key]);
    this.loading = false;
  },
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px; width: 100%`;
    },
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getListKey() {
      let key = this.data.list_data.find(
        (e) =>
          e.value == this.form[this.data.key] &&
          (e.name == this.form[this.data.key + "_info"] ||
            !this.form[this.data.key + "_info"])
      );
      if (key != undefined) {
        return key.name;
      }
      return "";
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  methods: {
    fetchOptions() {
      this.loading = true;
      if (this.data.is_global_variable && this.data.global_variable_id) {
        this.setGlobalVariableData();
      } else {
        this.options = this.data.list_data;
      }
      this.setValueFromForm();
      this.loading = false;
    },
    setValueFromForm() {
      if (this.data && this.form?.[this.data.key]) {
        if (this.form[this.data.key + "_info"]) {
          this.selectedValue =
            this.form[this.data.key] + "_" + this.form[this.data.key + "_info"];
        } else {
          let selectedOption = this.options.find(
            (e) => e.value == this.form[this.data.key]
          );
          if (selectedOption?.name) {
            this.selectedValue =
              this.form[this.data.key] + "_" + selectedOption.name;
          } else {
            this.selectedValue = this.form[this.data.key];
          }
        }
      }
    },
    // defaultMethod() {
    //   if (
    //     this.data.default_value &&
    //     this.form &&
    //     this.data.key &&
    //     !this.form[this.data.key]
    //   ) {
    //     //  this.form[this.data.key] = this.data.default_value;
    //     this.$set(this.form, this.data.key, this.data.default_value);
    //   }
    //   this.applyFormRules(this.selectedValue);
    //   this.$emit("applyFormRules");
    // },
    applyFormRules(value) {
      if (value) {
        this.$set(this.form, this.data.key, value.split("_")[0]);
        this.$set(this.form, this.data.key + "_info", value.split("_")[1]);
      } else {
        this.$set(this.form, this.data.key, "");
      }
      this.$emit("applyFormRules");
    },

    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "LIST") {
          this.isList = true;
          this.options = this.getGlobalVariableById.list_data;
        } else {
          this.options = this.data.list_data;
        }
      }
    },
  },
  // watch: {
  //   form: {
  //      handler() {
  //     //   this.setValueFromForm();
  //     //   // this.applyFormRules(this.selectedValue)
  //     // },
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  //   },
  //   "data.options": {
  //     handler() {
  //       this.options = this.data.list_data || [];
  //     },
  //   },
  created() {
    if (this.form[this.data.key]) {
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.setValueFromForm();
        const currentValue = newVal[this.data.key];
        if (this.isFirstLoad) {
          this.isFirstLoad = false; 
        } else if (!this.isFirstLoad && (currentValue === this.previousValue || this.isEditing)) {
          this.applyFormRules(this.selectedValue);
        }
        this.previousValue = currentValue;
      },
      deep: true,
      immediate: true,
    },
    "data.options": {
      handler() {
        this.options = this.data.list_data || [];
      },
    },
  },
};
</script>

<style lang="scss">
.highlighted-border .el-input__inner {
  border-color: red;
}
.execute-view {
  font-weight: normal;
}
</style>
